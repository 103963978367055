
import { Component, Vue } from "vue-property-decorator";
import Notify from "@/utils/notifications";
import DebtRuleDrawer from "@/components/Liink/DebtRuleDrawer.vue";
import DebtRuleCard from "@/components/Liink/DebtRuleCard.vue";
import Liink from "@/api/Liink";
import DebtRule from "@/models/Liink/DebtRule";
import Lender from "@/models/Liink/Lender";
import FinancialInstitution from "@/models/Liink/FinancialInstitution";

@Component({
  components: {
    DebtRuleCard,
    DebtRuleDrawer,
  },
})
export default class CatalogView extends Vue {
  loading = false;
  loadingDrawer = false;
  rule: DebtRule = {} as DebtRule;
  rules: Array<DebtRule> = [];
  lenders: Array<Lender> = [];
  financialInstitutions: Array<FinancialInstitution> = [];
  drawer = false;
  create = false;

  async createRule(data: unknown) {
    this.loadingDrawer = true;

    try {
      await Liink.createDebtRule(data);

      Notify.successful("La regla se creó correctamente.");
    } catch (err) {
      console.error(err);
    }

    this.closeDrawer();
    this.load();
  }

  async updateRule(id: number, data: unknown) {
    this.loadingDrawer = true;

    try {
      await Liink.updateDebtRule(id, data);

      Notify.successful("La regla se actualizó correctamente.");
    } catch (err) {
      console.error(err);
    }

    this.closeDrawer();
    this.load();
  }

  async deleteRule(id: number) {
    try {
      await Liink.deleteDebtRule(id);

      Notify.successful("La regla se eliminó correctamente.");
    } catch (err) {
      console.error(err);
    }

    this.load();
  }

  openDrawer(rule?: DebtRule) {
    this.create = rule == undefined;
    this.rule = rule
      ? rule
      : ({ isActive: true, latePayments: false } as DebtRule);
    this.drawer = true;
  }

  closeDrawer() {
    this.loadingDrawer = false;
    this.drawer = false;
    this.rule = {} as DebtRule;
  }

  async load() {
    this.loading = true;

    try {
      this.lenders = await Liink.getLenders();
    } catch (err) {
      console.error(err);
    }

    try {
      this.financialInstitutions = await Liink.getFinancialInstitution();
    } catch (err) {
      console.error(err);
    }

    try {
      this.rules = await Liink.getDebtRules();
    } catch (err) {
      console.error(err);
    }

    this.loading = false;
  }

  created() {
    const title = "Reglas de deudas";
    const index = "5.4";
    this.$store.commit("updateCurrentMenuIndex", index);
    this.$store.commit("updateCurrentTitle", title);
    this.$store.commit("updateBreadcrumbItems", [
      {
        text: "Liink",
        to: "/liink",
      },
      {
        text: title,
      },
    ]);

    this.load();
  }
}
